import axios from "axios";
import {authAPI} from "@/model/firebase";
import {executeAPIPost, executeAuthAPIGet} from "@/model/apiHelpers";
import {JobApplication, JobListing, User} from "@/types/shared";
import {errorHandler} from "@/model/apiErrors";

enum SERVER_PATH {
  PRODUCTION= 'https://olle.dscor.net/',
  TEST = 'https://test-api.dscor.net/',
  LOCAL = 'http://localhost:3001/'
}

export const apiPath = SERVER_PATH.PRODUCTION

export const uploadURL = `${apiPath}recruiting/upload`

/************************************************************************************
 *                               JobListings
 ***********************************************************************************/

export async function getUserApplications(listingId: string) {
  const response = await executeAuthAPIGet<JobApplication[]>('recruiting/userApplications/'+listingId)
  if (response.error) errorHandler(response.error)
  return response.data
}


/************************************************************************************
 *                               User
 ***********************************************************************************/
export async function getCurrentUser(): Promise<User|undefined> {
  const response = await executeAuthAPIGet<User>('currentUser')
  return response.data
}

export async function resetPassword(email: string): Promise<string|undefined> {
  const result = await executeAPIPost<string>('user/resetPassword', { email: email })
  return result.data
}
