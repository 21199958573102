import {computed, ref} from "vue";
import {Language} from "@/types/shared";

export const language =  ref<keyof typeof Language>('no')

export const languageOptions = [
  { value: "no", name: "🇳🇴" },
  { value: "en", name: "🇬🇧" },
]

export const localizations = {
  no: {
    attachments: {
      noAttachments: 'Ingen vedlegg lastet opp.',
      uploadedFiles: 'Opplastede filer:',
      addAttachment: 'Legg til vedlegg',
      toast: {
        uploadingError: 'Opplastingsfeil',
        success: 'Vedlegg lagt til',
        uploadedMessage: (length: number) => {
          return `Lastet opp ${length} ${(length === 1) ? 'fil' : 'filer'}.`
        },
        errorTitle: 'Kunne ikke laste opp filen',
        networkError: 'Klarte ikke å koble til serveren for å laste opp filer. Er du koblet til internett?',
      }


    },
    jobApplication: {
      showFullApplicationText: 'Vis hele annonseteksten',
      aboutHeading: 'Om søknaden',
      aboutText: 'Søknaden består av to deler. Del 1 er et skjema som skal fylles ut kombinert med opplasting av CV. Del 2 er en profilanalyse som kartlegger arbeidsrelaterte egenskaper som tar 30-60 minutter å gjennomføre. Det er bare aktuelle kandidater som vil få tilbakemelding på analyseresultatet.',
      partOneHeading: 'Søknad Del 1',
      personalInfo: 'Personlig info',
      firstName: 'Fornavn',
      lastName: 'Etternavn',
      email: 'Epost',
      phoneNumber: 'Telefonnummer',
      age: 'Alder',
      education: 'Utdannelse',
      educationLevels: [
        "Grunnskole eller lavere",
        "Videregående",
        "Minst 3 års høyere utdanning",
        "Minst 5 års høyere utdanning"
      ],
      otherQualifications: 'Andre kvalifikasjoner',
      storeProfileHeading: 'Lagring av CV og profil',
      storeProfileText: 'Hvis du eventuelt ikke ender opp i denne stillingen kan vi lagre profilen og CV\'en din for å kunne informere deg om andre aktuelle stillinger i fremtiden.',
      storeProfileCheckbox: 'Jeg ønsker at profilen og CV\'en min lagres utover denne søknaden.',
      applicationTextHeading: 'Søknadstekst',
      attachmentsHeading: 'Vedlegg',
      attachmentsText: 'Her kan du laste opp vedlegg som CV, referanser eller lignende.',
      privacyPolicyHTML: 'Jeg godtar <a href="/privacy_policy.html" target="_blank">personvernserklæringen</a>.',
      continueButton: 'Fortsett til Del 2',
      completedPartOneHeading: 'Fullført søknad del 1 av 2',
      thankYouPrefix: 'Takk for at du søker jobb hos ',
      nextStepDescription: 'For å fullføre søknaden må du gjennomføre en profilanalyse. Du vil nå få en e-post for å gjennomføre profileringen. Vi anbefaler at du har 30-60 minutter til rådighet før du setter i gang.',
      startNowText: 'Hvis du vil starte med en gang kan du også trykke på lenken under.'
    }
  },
  en: {
    attachments: {
      noAttachments: 'No attachments uploaded.',
      uploadedFiles: 'Uploaded files:',
      addAttachment: 'Add attachment',
      toast: {
        uploadingError: 'Uploading error',
        success: 'Attachment added',
        uploadedMessage: (length: number) => {
          return `Uploaded ${length} ${(length === 1) ? 'file' : 'files'}.`
        },
        errorTitle: 'Unable to upload file',
        networkError: 'Unable to connect to server. Are you connected to the internet?',
      }
    },
    jobApplication: {
      showFullApplicationText: 'Show job description',
      aboutHeading: 'About the application',
      aboutText: 'The application consists of two parts. Part 1 is a form combined with uploading of relevant attachments like CV. Part 2 is a personality assessment focused on work-related traits. The assessment takes 30-60 minutes to complete. PS: You will also receive a link to the assessment by email.',
      partOneHeading: 'Application Part 1',
      personalInfo: 'Personal info',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'E-mail',
      phoneNumber: 'Phone number',
      age: 'Age',
      education: 'Education',
      educationLevels: [
        "High school",
        "At least 3 years higher education",
        "At least 5 years higher education"
      ],
      otherQualifications: 'Other qualifications',
      storeProfileHeading: 'Storage of CV and profile',
      storeProfileText: 'If you are not selected for this position we are able to store your CV and information to inform you of other possible positions in the future.',
      storeProfileCheckbox: 'I wish for my information and CV to be stored beyond the scope of this application.',
      applicationTextHeading: 'Application text',
      attachmentsHeading: 'Attachments',
      attachmentsText: 'Upload attachments such as CV and references.',
      privacyPolicyHTML: 'I accept the <a href="/privacy_policy_en.html" target="_blank">privacy policy</a>.',
      continueButton: 'Continue to part 2',
      completedPartOneHeading: 'Completed application part 1 of 2',
      thankYouPrefix: 'Thank you for applying for a job at ',
      nextStepDescription: 'To complete the application you are required to complete a survey. We recommend that you have 30-60 minutes available before you get started. You will also receive an e-mail with a link and additional information about the survey.',
      startNowText: 'If you wish to get started right away you can click the link below.'
    }
  }
}

export const locStr = computed(() => {
  const lang = language.value
  console.log(lang)
  return localizations[lang]
})

