/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, {AxiosResponse} from "axios";
import {errorHandler, ErrorMessage, NotAuthenticatedError} from "@/model/apiErrors";
import {authAPI} from "@/model/firebase";
import {apiPath} from "@/model/api";

export const getAPIPath = () => {
  return apiPath
}

const getHeaders = () => {
  const headers: {[index: string]: string} = {
    'Content-Type': 'application/json',
  }
  return headers
}

async function getAuthHeaders() {
  const token = await authAPI.auth.currentUser?.getIdToken()
  if (!token) throw NotAuthenticatedError
  const authHeader =  `Bearer ${token}`
  console.info(authHeader)
    return {
    'Content-Type': 'application/json',
    'Authorization': authHeader
  }
}

export function mapToObject(key: any, value: any) {
  if(value instanceof Map) {
    return Object.fromEntries(value)
  } else {
    return value;
  }
}

export async function executeAPIPost<T>(path: string, data: any, headers: any = getHeaders()): Promise<APIResult<T>> {
  return axios.post(getAPIPath() + path, data, { headers: headers })
    .then(function (response: AxiosResponse<any>) {
      const data = response.data as T
      return {
        data: data,
      }
    }).catch(function (error) {
      return { error: errorHandler(error) }
    });
}

export async function executeAPIGet<T>(path: string, headers: any = getHeaders()): Promise<APIResult<T>> {

  return axios.get(getAPIPath() + path, { headers: headers })
    .then(function (response: AxiosResponse<any>) {
      const data = response.data as T
      return {
        data: data,
      }
    }).catch(function (error) {
      return { error: errorHandler(error) }
    });
}

export async function executeAuthAPIPost<T>(path: string, data: any): Promise<APIResult<T>> {
  const headers = await getAuthHeaders()
  return axios.post(getAPIPath() + path, data, { headers: headers })
    .then(function (response: AxiosResponse<any>) {
      const data = response.data as T
      return {
        data: data,
      }
    }).catch(function (error) {
      return { error: errorHandler(error) }
    });
}

export async function executeAuthAPIGet<T>(path: string): Promise<APIResult<T>> {
  return axios.get(getAPIPath() + path, { headers: await getAuthHeaders() })
    .then(function (response: AxiosResponse<any>) {
      const data = response.data as T
      return {
        data: data,
      }
    }).catch(function (error) {
      return { error: errorHandler(error) }
    });
}

export interface APIResult<T> {
  data?: T,
  error?: ErrorMessage
}
