<template>
  <div style="background-color: #607D8B; display:flex; align-items: center; height: 2.5em;">
    <b style="padding-left: 1em; font-size: 1em; color: white;">D-SCOR Job Application Platform</b>
  </div>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: white;
  color: #2c3e50;
}
</style>
