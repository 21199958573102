/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

enum ErrorCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_ERROR = 500,
  GONE = 410,
}

export class APIError extends Error {
  status: ErrorCode
  code: number

  constructor(status: ErrorCode, message: string, code: number) {
    super(message);
    this.name = 'APIError'
    this.status = status
    this.code = code
  }

  static InvalidAccountIdError(accountId: string) {
    return new APIError(ErrorCode.UNAUTHORIZED,  `InvalidAccountIdError: ${accountId}`, 1001)
  }
  static NotAuthenticatedError = new APIError(ErrorCode.UNAUTHORIZED,  "NotAuthenticatedError", 1002)
  static NotAdministratorError = new APIError(ErrorCode.FORBIDDEN,  "NotAdministratorError", 1003)
  static AccessTokenMissingError = new APIError(ErrorCode.NOT_FOUND,  "AccessTokenMissingError", 1004)
  static AccessTokenNotValidError = new APIError(ErrorCode.NOT_FOUND,  "AccessTokenNotValidError", 1005)
  static WriteAccessMissingError = new APIError(ErrorCode.FORBIDDEN,  "WriteAccessMissingError", 1006)

  static ParentGroupAccessError = new APIError(ErrorCode.FORBIDDEN, "ParentGroupAccessError", 2001)
  static ParentGroupIdMissingError = new APIError(ErrorCode.BAD_REQUEST, "ParentGroupIdMissingError", 2002)
  static GroupIdMissingError = new APIError(ErrorCode.BAD_REQUEST, "GroupIdMissingError", 2003)
  static GroupIdNotAvailableError = new APIError(ErrorCode.BAD_REQUEST, "GroupIdNotAvailableError", 2004)
  static GroupNameMissingError = new APIError(ErrorCode.BAD_REQUEST, "GroupNameMissingError", 2005)
  static GroupTreeMissingError = new APIError(ErrorCode.BAD_REQUEST, "GroupTreeMissingError", 2006)
  static GroupNotFoundError = new APIError(ErrorCode.NOT_FOUND, "GroupNotFoundError", 2007)

  static SurveyNotFoundError = new APIError(ErrorCode.NOT_FOUND, "SurveyNotFoundError", 3001)
  static SurveyCompletedError = new APIError(ErrorCode.NOT_FOUND, "SurveyCompletedError", 3002)
  static SurveyIdNotFoundError = new APIError(ErrorCode.BAD_REQUEST, "SurveyIdNotFoundError", 3003)
  static SurveyNotScoredError = new APIError(ErrorCode.BAD_REQUEST, "SurveyNotScoredError", 3004)
  static SurveyScoringCompletedError = new APIError(ErrorCode.BAD_REQUEST, "SurveyScoringCompletedError", 3002)
  static InvalidTraitListError = new APIError(ErrorCode.BAD_REQUEST, "InvalidTraitListError", 3011)

  static ProfileIdMissingError = new APIError(ErrorCode.BAD_REQUEST, "ProfileIdMissingError", 4001)
  static ProfileNotFoundError = new APIError(ErrorCode.NOT_FOUND, "ProfileNotFoundError", 4002)
  static ProfileExistsError = new APIError(ErrorCode.BAD_REQUEST, "ProfileExistsError", 4003)
  static ProfileNotAccessableError = new APIError(ErrorCode.FORBIDDEN, "ProfileNotAccessableError", 4004)

  static MissingNameError = new APIError(ErrorCode.BAD_REQUEST,  "MissingNameError", 4011)
  static EmailMissingError = new APIError(ErrorCode.NOT_FOUND, "EmailMissingError", 4012)
  static EmailInUseError = new APIError(ErrorCode.BAD_REQUEST, "EmailInUseError", 4013)
  static InvalidEmailError = new APIError(ErrorCode.BAD_REQUEST, "InvalidEmailError", 4014)

  static UserIdMissingError = new APIError(ErrorCode.BAD_REQUEST,  "UserIdMissingError", 5001)
  static UserNotFoundError = new APIError(ErrorCode.NOT_FOUND,  "UserNotFoundError", 5002)
  static UserWriteAccessMissingError = new APIError(ErrorCode.FORBIDDEN,  "UserWriteAccessMissingError", 5005)
  static UserLoginAccountExistsError = new APIError(ErrorCode.FORBIDDEN,  "UserLoginAccountExistsError", 5006)

  static MembershipIdMissingError = new APIError(ErrorCode.BAD_REQUEST,  "MembershipIdMissingError", 5001)
  static MembershipNotFoundError = new APIError(ErrorCode.NOT_FOUND,  "MembershipNotFoundError", 5002)
  static MembershipAlreadyExists = new APIError(ErrorCode.BAD_REQUEST,  "MembershipAlreadyExists", 5005)

  static CommentTextMissingError = new APIError(ErrorCode.NOT_FOUND, "CommentTextMissingError", 8001)
  static CommentNotFoundError = new APIError(ErrorCode.NOT_FOUND, "CommentNotFoundError", 8002)
  static InternalDatabaseError = new APIError(ErrorCode.INTERNAL_ERROR, "InternalDatabaseError", 8003)

  static InvalidAnswerDataError = new APIError(ErrorCode.INTERNAL_ERROR, "IncorrectAnswerDataError", 8004)

  // Recruiting API
  static JobListingIdMissingError = new APIError(ErrorCode.BAD_REQUEST, "JobListingIdMissingError", 11001)
  static JobApplicationIdMissingError = new APIError(ErrorCode.BAD_REQUEST, "JobApplicationIdMissingError", 11002)
  static JobListingNotFoundError = new APIError(ErrorCode.NOT_FOUND, "JobListingNotFoundError", 11004)
  static JobApplicationNotFoundError = new APIError(ErrorCode.NOT_FOUND, "JobApplicationNotFoundError", 11005)
  static AttachmentNotFoundError = new APIError(ErrorCode.NOT_FOUND, "AttachmentNotFoundError", 11006)


  static FirebaseCreateAccountError(message: string, code: number = ErrorCode.BAD_REQUEST) {
    return new APIError(code, `Unable to create new account: ${message}`, 4020)
  }

  static FirebaseResetPasswordtError(message: string, code: number = ErrorCode.BAD_REQUEST) {
    return new APIError(code, `Unable to reset password: ${message}`, 4020)
  }

  static BadRequestError(message: string) {
    return new APIError(ErrorCode.BAD_REQUEST, message, 400)
  }

  static InternalError(message: string) {
    return new APIError(ErrorCode.BAD_REQUEST, message, 500)
  }

  static NotFoundError(message: string) {
    return new APIError(ErrorCode.NOT_FOUND, message, 404)
  }

}

import {mainToast, ToastMessage} from "@/model/store";

enum APIErrorMessages {
  "You don't have access to perform this operation." = 1003
}

enum IgnoredAPIErrors {
  "NotAuthenticatedError"= 1002
}

export interface ErrorMessage {
  errorName: string
  errorMessage: string
  errorCode: number
  requestBody?: any
  requestParameters?: any
}

export interface LocalError extends Error {
  errorCode: number
}

export const NotAuthenticatedError: LocalError = {
  errorCode: 401,
  message: "Not signed in",
  name: "Unauthorized"
}

function localErrorToErrorMessage(error: LocalError): ErrorMessage {
  return {
    errorName: error.name,
    errorMessage: error.message,
    errorCode: error.errorCode
  }
}

export function errorHandler(error: any): ErrorMessage {
  if (error == NotAuthenticatedError) return localErrorToErrorMessage(error) // we don't care about this error, it's local to the app.
  console.error(error)
  const errorData = error.response?.data?.error as ErrorMessage|undefined
  if (errorData?.errorName === "APIError") {
    // Handle the error message as an APIError, which should be known to us.
    handleAPIError(errorData)
    // Return the error message to the caller function.
    return errorData
  }

  showToastErrorMessage(error.message)
  // Return a generic error to the caller function.
  const message = {
    errorName: 'AxiosError',
    errorCode: error.response?.status,
    errorMessage: error.message
  }
  return message
}

function handleAPIError(error: ErrorMessage) {
  // Check if the error code is something the user needs to worry about:
  let message = APIErrorMessages[error.errorCode]
  if (message) {
    showToastErrorMessage(message)
    return
  }
  message = IgnoredAPIErrors[error.errorCode]
  if (message) {
    console.warn(message)
    return
  }
  console.error(error)
}

export function showToastErrorMessage(message: string): void {
  const toastMessage: ToastMessage = {
    severity: "error",
    summary: `Error`,
    detail: `${message}`,
    life: 10000
  }
  if (mainToast.value) mainToast.value(toastMessage)
}

export function showToastError(error: Error): void {
  const message: ToastMessage = {
    severity: "error",
    summary: "Error",
    detail: `${error}`,
    life: 10000
  }
  if (mainToast.value) mainToast.value(message)
}
