import {JobListing} from "@/types/shared";
import {executeAPIGet, executeAPIPost, executeAuthAPIGet, executeAuthAPIPost} from "@/model/apiHelpers";
import {ErrorCodes} from "vue";
import {APIError} from "@/model/apiErrors";


export const getJobListing = async (listingId: string): Promise<JobListing|undefined> => {
  const response = await executeAPIGet<JobListing>(`recruiting/listing/${listingId}`)
  return response.data
}

export const getAllJobListings = async (): Promise<JobListing[]|undefined> => {
  const response = await executeAuthAPIGet<JobListing[]>(`recruiting/listings/`)
  return response.data
}


/*
export const storeUsername = async function (accessToken: string, username: string): Promise<any> {
  return executeAPIPost('survey/username', { accessToken: accessToken, username: username })
}

export const loadAnswerDataFromServer = async function (): Promise<any> {
  const response = await executeAPIGet('tests')
  return response
}*/

export interface CreateNewUserBody {
  email: string
  password: string
  name: string
  phoneNumber?: string
  birthYear?: number
}
export interface CreateNewUserResponse {
  accountId?: string
}
export async function createNewUser(name: string, email: string, password: string, phoneNumber: string, birthYear: number): Promise<string|undefined> {
  const payload: CreateNewUserBody = {
    birthYear: birthYear,
    email: email,
    name: name,
    phoneNumber: phoneNumber,
    password: password
  }
    const response = await executeAPIPost<CreateNewUserResponse>('recruiting/signup', payload)
    if (response.error) {
      if (response.error.errorCode === APIError.EmailInUseError.code) return 'Email is in use.'
      return response.error.errorMessage
    }
    return
}


