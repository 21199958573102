import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from "@/views/Login.vue";
import Signup from "@/views/Signup.vue";
import JobApplicationPage from "@/views/JobApplicationPage.vue"
import JobList from "@/views/JobsList.vue"

import { getAuth } from "firebase/auth"
import Home from "@/views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login/:redirect(.*)?',
    name: 'login',
    component: Login,
    props: true,
  },
  {
    path: '/signup/:redirect(.*)?',
    name: 'Signup',
    component: Signup,
    props: true
  },
  {
    path: '/l/:listingId',
    name: 'JobApplicationPage',
    component: JobApplicationPage,
    props: true,
    meta: {requiresAuth: true}
  },
  {
    path: '/',
    name: 'JobList',
    component: JobList,
    meta: {requiresAuth: true}
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = getAuth().currentUser;
  if(requiresAuth && !isAuthenticated) {
    next(`/signup`+to.path);
  }
  else {
    next();
  }
})

export default router
