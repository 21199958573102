import {computed, ref} from "vue";
import {executeAPIPost, executeAuthAPIPost} from "@/model/apiHelpers";
import {authAPI} from "@/model/firebase";


export enum LanguageCode {
    no = "no",
    se = "se",
    dk = "dk",
    en = "en"
}

export interface UploadedFile {
    filePath: string
    name: string
}

export interface UploadFileResponse {
    files: UploadedFile[]
}

export const currentUserEmail = computed(() => {
    return authAPI.auth.currentUser?.email
})


/************************************************************************************
 *                               Toast Message Handler
 ***********************************************************************************/

export interface ToastMessage {severity: string, summary: string, detail: string, life: number}
export const mainToast = ref<((message: ToastMessage) => void) |undefined>(undefined)

export function showToastMessage(message: {severity: string, summary: string, detail: string, life: number}): void {
    if (mainToast.value) mainToast.value(message)
}

enum ProfileStatus {
    NOT_SENT,
    SURVEY_SENT,
    COMPLETED,
}

export const attachments = ref(new Array<UploadedFile>())
export const applicationText = ref('')
export const education = ref('')
export const wantsJobOffers = ref(false)

export const profilingStatus = ref(ProfileStatus.NOT_SENT)

export const addAttachments = (xhr: XMLHttpRequest): string|undefined => {
    if (xhr.status !== 201) return `Error uploading file: ${xhr.response}`
    const response = JSON.parse(xhr.response)
    console.log(response)
    const added = response.files
    console.log(added)
    if (!added) return 'No files added!'
    if (added) {
        for (const addedElement of added) {
            attachments.value.push(addedElement)
        }
    }
    console.log(attachments)
}
export interface SubmitApplicationPayload {
    jobListingId: string
    education: string
    applicationText: string
    attachments: UploadedFile[]
    checkedQuestions: string[]
    wantsOtherJobOffers?: boolean
}
export interface SubmitApplicationResponse {
    accessToken?: string
    profileCompleted: boolean
}

export interface SendApplicationResult {
    errorMessage?: string
    accessToken?: string
    profileCompleted?: boolean
}

export async function sendApplication(listingId: string, answers: string[]): Promise<SendApplicationResult|undefined> {
    console.log(answers)
    if (!education.value) return { errorMessage: "Utdanning mangler." }
    if (!applicationText.value) return { errorMessage:  "Søknadstekst mangler." }
    if (!attachments.value.length) return { errorMessage:  "Vedlegg mangler." }

    const payload: SubmitApplicationPayload = {
        jobListingId: listingId,
        education: education.value,
        applicationText: applicationText.value,
        checkedQuestions: answers,
        attachments: attachments.value,
        wantsOtherJobOffers: wantsJobOffers.value
    }

    const response = await executeAuthAPIPost<SubmitApplicationResponse>('recruiting/submit', payload)

    console.log(response)

    if (response.data?.profileCompleted) profilingStatus.value = ProfileStatus.COMPLETED
    else if (response.data?.accessToken) profilingStatus.value = ProfileStatus.SURVEY_SENT

    return response.data
}


