
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ExpandableBaseballCard",
  props: {
    defaultExpanded: {
      default: false
    },
    expandLabel: {
      required: true,
      type: String
    },
  },
  data() {
    return {
      expanded: this.defaultExpanded
    }
  },
  methods: {
    expand() {
      this.expanded = !this.expanded;
    },
  }
})
