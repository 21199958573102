
import {defineComponent} from 'vue'
import {attachments, UploadedFile} from "@/model/store";

export default defineComponent({
  name: "FileListItem",
  props: {
    file: {
      required: true,
      type: Object as () => UploadedFile
    },
  },
  methods: {
    deleteFile() {
      attachments.value = attachments.value.filter(a => (a.filePath !== this.file.filePath))
    }
  }
})
