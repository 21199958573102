import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import { getAuth } from 'firebase/auth'

import "primevue/resources/themes/saga-blue/theme.css"       //theme
import "primevue/resources/primevue.min.css"                 //core css
import "primeicons/primeicons.css"                           //icons

import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import RadioButton from "primevue/radiobutton";
import InlineMessage from "primevue/inlinemessage";
import Paginator from "primevue/paginator";
import ProgressBar from "primevue/progressbar";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Checkbox from "primevue/checkbox";
import FileUpload from "primevue/fileupload";
import InputMask from "primevue/inputmask";
import Message from "primevue/message";



let app:any
getAuth().onAuthStateChanged(user => {
    if(!app) {
        app = createApp(App)
        app.use(router)
        app.use(PrimeVue)
        app.use(ToastService)

        app.component('Dialog', Dialog);
        app.component('RadioButton', RadioButton);
        app.component('InlineMessage', InlineMessage);
        app.component('Paginator', Paginator);
        app.component('ProgressBar', ProgressBar);
        app.component('Button', Button);
        app.component('InputText', InputText);
        app.component('Textarea', Textarea);
        app.component('Dropdown', Dropdown);
        app.component('Checkbox', Checkbox);
        app.component('Toast', Toast)
        app.component('FileUpload', FileUpload)
        app.component('InputMask', InputMask)
        app.component('Message', Message)

        app.mount('#app')
    }
})
