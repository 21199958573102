import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"display":"flex","flex-direction":"column","background-color":"white","border-radius":"10px"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: {"text-decoration":"underline"} }
const _hoisted_4 = {
  key: 0,
  class: "pi pi-chevron-up"
}
const _hoisted_5 = {
  key: 1,
  class: "pi pi-chevron-down"
}
const _hoisted_6 = {
  key: 0,
  style: {"margin-top":"0em","padding-left":"1em","padding-right":"1em","padding-bottom":"1em"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.expand && _ctx.expand(...args))),
      style: {"padding":"0em 2em 1em 1em","cursor":"pointer","display":"flex","flex-direction":"row","justify-content":"space-between","align-items":"center"}
    }, [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "header"),
        (!_ctx.expanded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.expandLabel), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        (_ctx.expanded)
          ? (_openBlock(), _createElementBlock("i", _hoisted_4))
          : (_openBlock(), _createElementBlock("i", _hoisted_5))
      ])
    ]),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "content")
        ]))
      : _createCommentVNode("", true)
  ]))
}