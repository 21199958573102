import * as firebase from "firebase/app";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import router from "@/router/router";

const firebaseConfig = {
  apiKey: "AIzaSyBE0Et8FaTXqpuQpd0n11OGcHdy-nE5-ts",
  authDomain: "d-scor.firebaseapp.com",
  projectId: "d-scor",
  storageBucket: "d-scor.appspot.com",
  messagingSenderId: "771834087064",
  appId: "1:771834087064:web:aa109f1a9b5b0d0cc5b2ed"
};

firebase.initializeApp(firebaseConfig);


export const authAPI = {
  auth: getAuth(),
  signIn: signInWithEmailAndPassword
}

export const firebaseSignIn = async (email: string, password: string): Promise<boolean> => {
  const auth = authAPI.auth;
  return await authAPI.signIn(auth, email, password)
    .then((userCredential: any) => {
      console.log(userCredential.user)
      return true
    })
    .catch((error: any) => {
      console.log(error)
      return false
    })
}

export const signOut = async (): Promise<void> => {
  await authAPI.auth.signOut()
  // TODO: Flush store
  await router.push({ name: 'login' })
}
