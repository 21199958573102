
import {defineComponent} from "vue";
import {authAPI} from "@/model/firebase";
import {resetPassword} from "@/model/api";
import Message from "primevue/message";
import router from "@/router/router";

export default defineComponent({
  props: {
    redirect: {
      required: false,
      type: String
    },
  },
    data() {
        return {
            email: '',
            password: '',
            error: '',
          infoMessage: ''
        }
    },
  computed: {

  },
    methods: {
        async pressed() {
            try {
              const auth = authAPI.auth;
              await authAPI.signIn(auth, this.email, this.password)
                .then((userCredential: any) => {
                  console.log(userCredential.user)
                  if (this.redirect) {
                    this.$router.push('/'+this.redirect)
                  } else {
                    this.$router.push('/')
                  }
                })
                .catch((error: any) => {
                  if (error.code === "auth/wrong-password") { this.error = "Incorrect password." }
                  else if (error.code === "auth/user-not-found") { this.error = "User not found." }
                  else {
                    this.error = error.code
                  }
                  console.log(error)
                })
            } catch(err) {
              console.log(err)
            }
        },
      async sendResetPasswordMail() {
        if (!this.email) {
          this.error = 'E-mail missing'
          return
        }
        this.error = ''
        const sentToMail = await resetPassword(this.email)
        if (sentToMail) {
        this.infoMessage = `Reset e-mail sent to ${this.email}.`
        } else { this.error = 'Something went wrong. Please verify that the email is correct.' }
      },
      goToSignup () {
        router.push('/signup/'+this.redirect)
      }
    }
})
